import React, { useState } from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import ModalLayout from '../../ModalLayout/ModalLayout';
import Approved from '../Approved/Approved';
import { useModal } from '../../../../contexts/ModalContext';
import { useHttp } from '../../../../contexts/HttpContext';
import { useUser } from '../../../../contexts/UserContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

const Check = ({ icon, text, url, id, type }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { open } = useModal();
  const { post, get } = useHttp();
  const { hardRefreshFunc, getUser } = useUser();
  const { lang } = useLanguage();

  const checkTask = () => {
    setLoading(true);

    post(`/api/tasks/${id}`, { taskId: id })
      .then((r) => {
        if (r.status === 'error') {
          setError(r.message);
        } else {
          hardRefreshFunc();
          getUser();
          open(<ModalLayout element={<Approved />} />);
        }
      })
      .finally(() => setLoading(false));
  };

  const onLinkClick = () => {
    if (type !== 'Any') return;

    get(`/api/tasks/is-complete/${id}`);
  };

  return (
    <div className={styles.container}>
      <img
        className={styles.icon}
        src={icon}
        alt=''
      />
      <span className={styles.text}>{text}</span>
      <div className={styles.buttons}>
        {url && (
          <Link
            onClick={onLinkClick}
            to={url}
          >
            {lang.modals.complete}
          </Link>
        )}
        <button
          onClick={checkTask}
          disabled={loading}
        >
          {lang.modals.check}
        </button>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default Check;
