import React from 'react';

import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import QuestionImage from '../../../../media/png/modal-question.png';
import styles from './Header.module.css';

import RulesModal from '../RulesModal';

const Header = () => {
  const { open } = useModal();
  const { lang } = useLanguage();

  const onOpenRulesModal = () => {
    open(<RulesModal />);
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerTitleContainer}>
        <div className={styles.headerIcon} />
        <div className={styles.title}>{lang.wordle.title}</div>
      </div>
      <div className={styles.headerHint}>
        <button
          className={styles.button}
          onClick={onOpenRulesModal}
        >
          <img
            src={QuestionImage}
            alt=''
          />
        </button>
      </div>
    </div>
  );
};

export default Header;
