import FriendIcon from '../../../media/png/box/friends-icon.svg';
import DoomerIcon from '../../../media/png/doomer.png';
import CaseIcon from '../../../media/png/case-image.png';
import TasksIcon from '../../../media/png/tasks-title.png';
import { useLanguage } from '../../../contexts/LanguageContext';

const useNavLinks = () => {
  const { lang } = useLanguage();
  return [
    {
      id: 1,
      label: lang.box.invite,
      link: '/friends',
      icon: FriendIcon,
    },
    {
      id: 2,
      label: lang.box.completeTasks,
      link: '/income',
      icon: TasksIcon,
    },
    {
      id: 3,
      label: lang.box.doomer,
      link: '/',
      icon: DoomerIcon,
    },
    {
      id: 4,
      label: lang.box.cases,
      link: '/cases',
      icon: CaseIcon,
    },
  ];
};

export default useNavLinks;
