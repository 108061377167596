import { useLanguage } from '../../../../contexts/LanguageContext';
import wordleBannerEng from '../../../../media/png/wordle-banner-eng.png';
import wordleBannerRu from '../../../../media/png/wordle-banner-ru.png';
import styles from '../../WordGame.module.css';

const Banner = () => {
  const { currentLang } = useLanguage();

  const bannerImage = currentLang === 'en' ? wordleBannerEng : wordleBannerRu;

  return (
    <div className={styles.banner}>
      <div
        className={styles.bannerImage}
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      />
    </div>
  );
};

export default Banner;
