import React from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

import CloseIcon from '../../../Wallet/components/CloseButton';
import { ShareButton } from '../../../../shared/components';
import Button from '../../ui/Button';
import styles from './ImportantInfoModal.module.css';

const ImportantInfoModal = ({ requiredFriends = 1 }) => {
  const { close } = useModal();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        onClick={close}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.content}>
        <h1 className={styles.title}>{lang.box.importantInfo}</h1>
        <p className={styles.text}>
          {lang.box.importantInfoDescription.first} {requiredFriends}{' '}
          {lang.box.importantInfoDescription.second}
        </p>

        <p className={styles.text}>{lang.box.importantInfoDescription.third}</p>

        <Button
          className={styles.okayButton}
          onClick={close}
          text={lang.box.okay}
        />
        <ShareButton className={styles.shareButton} />
      </div>
    </div>
  );
};

export default ImportantInfoModal;
