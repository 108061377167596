import useGiftsList from '../../hooks/useGiftsList';
import GiftsElement from './GiftsElement';
import styles from './GiftsModal.module.css';

const GiftsList = () => {
  const { giftsList } = useGiftsList();

  return (
    <div className={styles.list}>
      {giftsList.map((gift) => (
        <GiftsElement
          key={gift.id}
          {...gift}
        />
      ))}
    </div>
  );
};

export default GiftsList;
