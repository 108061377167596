import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Friends from '../components/Friends';
import Income from '../components/Income';
import Improvements from '../components/Improvements';
import Positions from '../components/Positions/Positions';
import Actives from '../components/Actives/Actives';
import Leaderboard from '../components/Leaderboard/Leaderboard';
import Achievements from '../components/Achievements/Achievements';
import Daily from '../components/Daily/Daily';
import Cases from '../components/Cases';
import Wallet from '../components/Wallet';
import SetupLanguage from '../components/SetupLanguage';
import WordGame from '../components/WordGame';
import TechnicalWorks from '../components/TechincalWorks';
import Home from '../components/Home/Home';
import { BoxPage } from '../components/BoxPage';

const Router = () => (
  <Routes>
    <Route
      path={'/'}
      element={<Home />}
    />
    <Route
      path={'/friends'}
      element={<Friends />}
    />
    <Route
      path={'/actives'}
      element={<Actives />}
    />
    <Route
      path={'/income'}
      element={<Income />}
    />
    <Route
      path={'/improvements'}
      element={<Improvements />}
    />
    <Route
      path={'/positions'}
      element={<Positions />}
    />
    <Route
      path={'/leaderboard'}
      element={<Leaderboard />}
    />
    <Route
      path={'/achievements'}
      element={<Achievements />}
    />
    <Route
      path={'/daily'}
      element={<Daily />}
    />
    <Route
      path={'/cases'}
      element={<Cases />}
    />
    <Route
      path={'/wallet'}
      element={<Wallet />}
    />
    <Route
      path={'/language'}
      element={<SetupLanguage />}
    />
    <Route
      path={'/words'}
      element={<WordGame />}
    />
    <Route
      path={'/technical-works'}
      element={<TechnicalWorks />}
    />
    <Route
      path={'/box'}
      element={<BoxPage />}
    />
  </Routes>
);

export default Router;
