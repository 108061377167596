import { useLanguage } from '../../../contexts/LanguageContext';

import Gift1Icon from '../../../media/png/box/gift_1.png';
import Gift2Icon from '../../../media/png/box/gift_2.png';
import Gift3Icon from '../../../media/png/box/gift_3.png';
import Gift4Icon from '../../../media/png/box/gift_4.png';
import Gift5Icon from '../../../media/png/box/gift_4.png';

import Gift1IconClose from '../../../media/png/box/1.png';
import Gift2IconClose from '../../../media/png/box/2.png';
import Gift3IconClose from '../../../media/png/box/3.png';
import Gift4IconClose from '../../../media/png/box/4.png';
import Gift5IconClose from '../../../media/png/box/5.png';

import Gift1IconOpen from '../../../media/png/box/1-1.png';
import Gift2IconOpen from '../../../media/png/box/2-2.png';
import Gift3IconOpen from '../../../media/png/box/3-3.png';
import Gift4IconOpen from '../../../media/png/box/4-4.png';
import Gift5IconOpen from '../../../media/png/box/5-5.png';

const useGiftsList = () => {
  const { lang } = useLanguage();

  const giftsList = [
    {
      id: 1,
      label: lang.box.gift1,
      smallIcon: Gift1Icon,
      largeIconClose: Gift1IconClose,
      largeIconOpen: Gift1IconOpen,
      price: 20,
      vault: 'TON',
    },
    {
      id: 2,
      label: lang.box.gift2,
      smallIcon: Gift2Icon,
      largeIconClose: Gift2IconClose,
      largeIconOpen: Gift2IconOpen,
      price: 40,
      vault: 'TON',
    },
    {
      id: 3,
      label: lang.box.gift3,
      smallIcon: Gift3Icon,
      largeIconClose: Gift3IconClose,
      largeIconOpen: Gift3IconOpen,
      price: 60,
      vault: 'TON',
    },
    {
      id: 4,
      label: lang.box.gift4,
      smallIcon: Gift4Icon,
      largeIconClose: Gift4IconClose,
      largeIconOpen: Gift4IconOpen,
      price: 80,
      vault: 'TON',
    },
    {
      id: 5,
      label: lang.box.gift5,
      smallIcon: Gift5Icon,
      largeIconClose: Gift5IconClose,
      largeIconOpen: Gift5IconOpen,
      price: 100,
      vault: 'TON',
    },
  ];

  return { giftsList };
};

export default useGiftsList;
