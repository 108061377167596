import React from 'react';

import TonIcon from './../../../../media/png/ton-icon.png';
import GiftIcon from './../../../../media/png/box/gift-icon.png';
import RightPointBracket from './../../../../media/png/right-point-bracket.png';

import { useLanguage } from '../../../../contexts/LanguageContext';

import styles from './Footer.module.css';

const MenuContent = ({ currentScore, maxScore, onClicksInfoModalOpen, onGiftsModalOpen, gift }) => {
  const { lang } = useLanguage();
  const TON_BONUS_VALUE = gift.price.toFixed(2);
  const TON_BONUS_TEXT = gift.vault;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.boxScore}>
          <div className={styles.boxScoreValue}>
            {currentScore} / {maxScore}
          </div>
          <img
            className={styles.giftIcon}
            src={GiftIcon}
            alt='gift-icon'
          />
        </div>

        <div
          className={styles.earn}
          onClick={onGiftsModalOpen}
        >
          <div className={styles.earnText}>
            {lang.box.earnText} {TON_BONUS_VALUE} {TON_BONUS_TEXT}
          </div>
          <img
            className={styles.tonIcon}
            src={TonIcon}
            alt='ton-icon'
          />
          <img
            className={styles.bracket}
            src={RightPointBracket}
            alt='right-point-bracket'
          />
        </div>

        <div
          className={styles.clicks}
          onClick={onClicksInfoModalOpen}
        >
          <span className={styles.clicksText}>{lang.box.clicks}</span>
          <div className={styles.tasks}>
            <span className={styles.tasksText}>{lang.box.tasks}</span>
            <img
              src={RightPointBracket}
              alt='right-point-bracket'
            />
          </div>
          <div className={styles.clicksIconWrapper}>
            <div className={styles.clicksIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuContent;
