import React from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

import GiftsHeader from './GiftsHeader';
import GiftsList from './GiftsList';
import CloseIcon from '../../../Wallet/components/CloseButton';
import Button from '../../ui/Button';

import styles from './GiftsModal.module.css';

const GiftsModal = () => {
  const { close } = useModal();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        onClick={close}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.content}>
        <GiftsHeader />
        <GiftsList />
        <Button
          onClick={close}
          text={lang.box.okay}
        />
      </div>
    </div>
  );
};

export default GiftsModal;
