import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useModal } from '../../../../contexts/ModalContext';
import CloseIcon from '../../../Wallet/components/CloseButton';

import Description from './ui/Description';
import Button from './ui/Button';
import Prizes from './ui/Prizes';
import RulesWordsWrapper from './ui/RulesWordsWrapper';

import styles from './RulesModal.module.css';

const RulesModal = () => {
  const { close } = useModal();
  const { lang } = useLanguage();
  const onPlay = () => {
    close();
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        onClick={close}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.content}>
        <h1 className={styles.title}>{lang.wordle.rules}</h1>
        <p className={styles.description}>{lang.wordle.description}</p>
      </div>

      <RulesWordsWrapper />
      <Prizes />
      <Description
        title={lang.wordle.whereFindAnswers}
        text={lang.wordle.answersText}
      />
      <Description
        title={lang.wordle.timeUpdate}
        text={lang.wordle.timeUpdateText}
      />
      <Button
        text={lang.wordle.play}
        onClick={onPlay}
      />
    </div>
  );
};

export default RulesModal;
