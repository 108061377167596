import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useModal } from '../../../../contexts/ModalContext';
import CloseIcon from '../../../Wallet/components/CloseButton';
import useNavLinks from '../../hooks/useNavLinks';
import Button from '../../ui/Button';
import ModalListElement from './ModalListElement';
import styles from './ClicksInfoModal.module.css';

const ClicksInfoModal = () => {
  const { close } = useModal();
  const { lang } = useLanguage();

  const navLinks = useNavLinks();

  const onPlay = () => {
    close();
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        onClick={close}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.content}>
        <h1 className={styles.title}>{lang.box.clicks}</h1>

        <div className={styles.list}>
          {navLinks.map((link) => (
            <ModalListElement
              key={link.id}
              onModalClose={close}
              {...link}
            />
          ))}
        </div>

        <Button
          text={lang.box.okay}
          onClick={onPlay}
        />
      </div>
    </div>
  );
};

export default ClicksInfoModal;
