import React from 'react';
import { ClickerProvider } from '../../contexts/ClickerContext';

import Header from './components/Header/Header';
import Coin from './components/Coin/Coin';
import Footer from './components/Footer/Footer';

import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Header />
        <ClickerProvider>
          <Coin />
        </ClickerProvider>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
