import React from 'react';

import CoinsListItem from './CoinsListItem';

import styles from './CoinsList.module.css';
import { coinsList, innerCoinsList, INNER_COINS_NAMES } from '../../../../Wallet/const';

const CoinsList = ({ statistics, onCoinClick, selectedCoin }) => {
  const mappedInnerCoinsList = innerCoinsList.map((coin) => ({
    ...coin,
    value: coin.name === INNER_COINS_NAMES.PVC ? statistics.pvcToEarn : statistics.doomCoinsToEarn,
  }));

  const mappedCoinsList = coinsList.map((coin) => ({
    ...coin,
    value: statistics.cryptoToEarn[coin.name] ?? statistics.pvcToEarn,
  }));


  const allCoinsList = [...mappedInnerCoinsList, ...mappedCoinsList];

  return (
    <div className={styles.income}>
      {allCoinsList.map((coin, index) => (
        <CoinsListItem 
          {...coin}
          key={index}
          coin={coin}
          selectedCoin={selectedCoin}
          onCoinClick={onCoinClick}
        />
      ))}
    </div>
  );
};

export default CoinsList
