import React, { useState, useEffect, useCallback } from 'react';

import { useTelegram } from '../../../../contexts/TelegramContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import useWordGame from '../../hooks/useWordGame';

import Loading from '../../../ui/Loading';
import OverlayMessage from './OverlayMessage';
import Grid from './Grid';
import Keyboard from './Keyboard';
import { useTimer } from '../../../../shared/hooks';

import styles from './Game.module.css';

const Game = () => {
  const { lang } = useLanguage();
  const { handleClick: vibrate } = useTelegram();

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayText, setOverlayText] = useState('');
  const [showPrizes, setShowPrizes] = useState(false);

  const ROWS = 6;
  const COLS = 5;

  const {
    guessedWords,
    error,
    isGameOver,
    isWin,
    loading,
    onAddLetter,
    onDeleteLetter,
    onSubmitWord,
    clearErrorState,
    getLetterStatus,
    history,
    nextWordUpdate: nextWordUpdateISODate,
  } = useWordGame();

  const timer = useTimer({ endTime: nextWordUpdateISODate, text: lang.wordle.updateIn });

  const displayOverlay = useCallback(
    (text) => {
      setOverlayText(text);
      setShowOverlay(true);
      setTimeout(() => {
        setShowOverlay(false);
        clearErrorState();
        setShowPrizes(false);
      }, 1500);
    },
    [clearErrorState],
  );

  useEffect(() => {
    if (error) {
      displayOverlay(error);
    } else if (isWin) {
      displayOverlay(lang.wordle.winText);
      setShowPrizes(true);
    } else if (isGameOver) {
      displayOverlay(lang.wordle.gameOverText);
    }
  }, [error, isWin, isGameOver, displayOverlay, lang.wordle.winText, lang.wordle.gameOverText]);

  return (
    <>
      <span className={styles.timer}>{timer}</span>

      <div className={styles.gameContainer}>
        <Loading
          className={styles.loadingSpinner}
          show={loading}
        />
        <OverlayMessage
          showOverlay={showOverlay}
          overlayText={overlayText}
          showPrizes={showPrizes}
        />
        <Grid
          rows={ROWS}
          cols={COLS}
          guessedWords={guessedWords}
        />
      </div>
      <Keyboard
        vibrate={vibrate}
        history={history}
        getLetterStatus={getLetterStatus}
        onSubmitWord={onSubmitWord}
        onDeleteLetter={onDeleteLetter}
        onAddLetter={onAddLetter}
      />
    </>
  );
};

export default Game;
