import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useUser } from '../../contexts/UserContext';

import useWallet from './hooks/useWallet';
import CoinsList from './components/CoinList';
import Header from './components/Header/Header';

import { Banner } from '../../shared/ui';

import FriendsImage from '../../media/png/wallet/friends-wallet-icon.png';
import CasesImage from '../../media/png/home/cases-icon.png';
import styles from './Wallet.module.css';

const Wallet = () => {
  const { lang } = useLanguage();
  const { user } = useUser();

  const { loading, walletData, onShowWidthdrawalModal } = useWallet();

  const availableCases = user.availableCases || 0;

  return (
    <div className={styles.container}>
      <Header />

      <div className={styles.banners}>
        <Banner
          imageClassName={styles.bannersImageContainer}
          disabled={true}
          showArrow={true}
          image={FriendsImage}
          title={lang.home.bannerTextReferrals}
          description={lang.home.bannerTextReferralsValue}
          link='/friends'
        />
        <Banner
          disabled={true}
          showArrow={false}
          image={CasesImage}
          title={lang.home.bannerTextCases}
          description={`${lang.home.avaliableCases} ${availableCases}`}
          link='/cases'
        />
      </div>

      <CoinsList
        loading={loading}
        data={walletData}
        onShowWidthdrawalModal={onShowWidthdrawalModal}
        lang={lang}
        fallback={
          <div className={styles.loading}>
            <div className={styles.spinner} />
          </div>
        }
      />
    </div>
  );
};

export default Wallet;
