import React, { useState, useEffect } from 'react';
import { useHttp } from '../../../contexts/HttpContext';
import { useModal } from '../../../contexts/ModalContext';

import { ClicksInfoModal, GiftsModal, ImportantInfoModal, SuccessModal } from '../components';

const useBox = () => {
  const { get } = useHttp();
  const { open } = useModal();
  const [loadingScore, setLoadingScore] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  const [boxState, setBoxState] = useState({
    currentScore: 0,
    maxScore: 20000,
    level: 0,
    clicksCompleted: false,
    completed: false,
    bonus: {
      value: '20',
      type: 'TON',
    },
  });

  const onModalOpen = (Component) => {
    open(Component, 'modal', { background: 'rgba(0, 0, 0, 0.3)' });
  };

  const onClicksInfoModalOpen = () => {
    onModalOpen(<ClicksInfoModal />);
  };

  const onGiftsModalOpen = () => {
    onModalOpen(<GiftsModal />);
  };

  const onImportantInfoModalOpen = () => {
    onModalOpen(<ImportantInfoModal />);
  };

  const onSuccessModalOpen = () => {
    onModalOpen(<SuccessModal />);
  };
  const onWithdraw = async () => {
    if (!boxState.completed) {
      onImportantInfoModalOpen();
      return;
    }

    try {
      setLoadingWithdraw(true);

      const result = await get('/api/gift/withdraw-crypto');

      if (result.status === 'success') {
        onSuccessModalOpen();
        await fetchGiftProgress();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingWithdraw(false);
    }
  };

  const fetchGiftProgress = async () => {
    try {
      setLoadingScore(true);
      const response = await get('/api/gift');

      if (response.status === 'success') {
        const { currentClicks, completed, currentLevel, clicksCompleted } = response.data.progress;
        const { levelNumber, requiredClicks, bonus } = currentLevel;

        const currentScore = currentClicks > requiredClicks ? requiredClicks : currentClicks;

        setBoxState({
          currentScore,
          maxScore: requiredClicks,
          level: levelNumber,
          clicksCompleted,
          completed,
          bonus,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingScore(false);
    }
  };

  useEffect(() => {
    fetchGiftProgress();
  }, []);

  return {
    boxState,
    loadingWithdraw,
    loadingScore,
    setBoxState,
    onClicksInfoModalOpen,
    onImportantInfoModalOpen,
    onGiftsModalOpen,
    onSuccessModalOpen,
    onWithdraw,
  };
};

export default useBox;
