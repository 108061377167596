import React from 'react';

import useNavLinks from './hooks/useNavLinks';
import FooterLink from './FooterLink';
import styles from './Footer.module.css';
import './Footer.css';

const Footer = () => {
  const navLinks = useNavLinks();

  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        {navLinks.map(({ url, img, text, disabled }) => (
          <FooterLink
            key={url}
            url={url}
            img={img}
            text={text}
            disabled={disabled}
          />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
