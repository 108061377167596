import React, { createContext, useContext, useCallback } from 'react';
import { useTelegram } from './TelegramContext';
import { useLanguage } from './LanguageContext';

const HttpContext = createContext();

export const HttpProvider = ({ children }) => {
  const { tg } = useTelegram();
  const { currentLang } = useLanguage();

  const decorateResponse = (response = {}, isSuccess = false) => {
    if (isSuccess) {
      return {
        status: 'success',
        message: response.message || '',
        data: response.result || response,
      };
    } else {
      return {
        status: 'error',
        message: response.message || 'Unknown error occurred',
        data: response.data || response,
      };
    }
  };

  const post = async (url = '/', body = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': `${currentLang}`,
          Authorization: tg.initData || process.env.REACT_APP_AUTHORIZATION,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!response.ok) {
        return decorateResponse(data, false);
      }
      return decorateResponse(data, true);
    } catch (e) {
      console.error('Fetch error: ', e);
      return decorateResponse(e, false);
    }
  };

  const get = useCallback(
    async (url = '/') => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': `${currentLang}`,
            Authorization: tg.initData || process.env.REACT_APP_AUTHORIZATION,
          },
        });
        const data = await response.json();
        if (!response.ok) {
          return decorateResponse(data, false);
        }
        return decorateResponse(data, true);
      } catch (e) {
        return decorateResponse(e, false);
      }
    },
    [currentLang, tg.initData],
  );

  return <HttpContext.Provider value={{ get, post }}>{children}</HttpContext.Provider>;
};

export const useHttp = () => useContext(HttpContext);
