export const words = {
  ru: [
    { word: 'РЕБУС', wordDescription: 'Буква С есть в загаданном слове, но стоит в другом месте.' },
    {
      word: 'СОСНА',
      wordDescription:
        'Затем ввели слово Сосна. Буква С и О есть в загаданном слове и стоят на правильных местах.',
    },
    { word: 'ГОСТЬ', wordDescription: 'Если слово угадано правильно, то все буквы будут выделены!' },
    { word: 'ЖИРАФ', wordDescription: 'Если буквы нет в загаданном слове, то она выделяется черным.' },
  ],
  en: [
    {
      word: 'STONE',
      wordDescription: 'The letters S, T and E is in the riddle word, but it is in a different place.',
    },
    {
      word: 'WHEEL',
      wordDescription:
        'Then the word WHEEL was entered. The letter H and E are in the riddle word and are in the correct places. The letter E is in the riddle word, but it is in a different place.',
    },
    {
      word: 'CHEST',
      wordDescription: 'If the word is guessed correctly, all letters will be highlighted!',
    },
    {
      word: 'ROUND',
      wordDescription: 'If a letter is not in the riddled word, it is highlighted in black.',
    },
  ],
};

export const keyboard = {
  en: {
    row1: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    row2: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    row3: ['backspace', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'enter'],
  },
  ru: {
    row1: ['Й', 'Ц', 'У', 'К', 'Е', 'Н', 'Г', 'Ш', 'Щ', 'З', 'Х', 'Ъ'],
    row2: ['Ф', 'Ы', 'В', 'А', 'П', 'Р', 'О', 'Л', 'Д', 'Ж', 'Э'],
    row3: ['backspace', 'Я', 'Ч', 'С', 'М', 'И', 'Т', 'Ь', 'Б', 'Ю', 'enter'],
  },
};
