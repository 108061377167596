import { useLanguage } from '../../../../../contexts/LanguageContext';

import StyledWord from './StyledWord';
import styles from '../RulesModal.module.css';
import { words } from '../../../const';

const RulesWordsWrapper = () => {
  const { currentLang } = useLanguage();

  const correctWords = {
    ru: 'ГОСТЬ',
    en: 'CHEST',
  };

  const currentLangWord = words[currentLang];

  return (
    <div className={styles.rulesDescriptionWrapper}>
      {currentLangWord.map(({ word, wordDescription }) => (
        <StyledWord
          key={word}
          word={word}
          correctWord={correctWords[currentLang]}
          wordDescription={wordDescription}
        />
      ))}
    </div>
  );
};

export default RulesWordsWrapper;
