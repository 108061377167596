import React from 'react';

import { useUser } from '../../../../contexts/UserContext';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { decorateBalance } from '../../../../utils/helpers';

import DoomerCoinIcon from '../../../../media/png/doomer.png';
import PvcIcon from '../../../../media/png/pvc.png';
import SettingsIcon from '../../../../media/svg/settings.svg';
import WordsImage from '../../../../media/png/home/words-icon.png';
import CasesImage from '../../../../media/png/home/cases-icon.png';

import Settings from '../../../modals/Settings';
import ModalLayout from '../../../modals/ModalLayout/ModalLayout';
import AnimatedCounter from '../AnimatedCounter/AnimatedCounter';
import { Banner } from '../../../../shared/ui';
import { useTimer } from '../../../../shared/hooks';

import styles from './Header.module.css';

const MOCK_TIME = new Date(new Date().getTime() + 60 * 60 * 1000).toISOString();

const Header = () => {
  const { lang } = useLanguage();
  const { user } = useUser();
  const { open } = useModal();

  const availableCases = user.availableCases || 0;
  const wordsUpdateTime = user.nextWordUpdate || MOCK_TIME;

  const timeLeft = useTimer({ endTime: wordsUpdateTime });

  const handleOpenSettings = () => {
    open(<ModalLayout element={<Settings />} />);
  };

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles.doomerCoin}>
            <img
              className={styles.doomerCoinIcon}
              src={DoomerCoinIcon}
              alt='doomer-coin'
            />
            <span className={styles.doomerCoinValue}>{decorateBalance(user.wallet.doomCoins)}</span>
          </div>

          <div className={styles.pvcCoin}>
            <img
              className={styles.pvcCoinIcon}
              src={PvcIcon}
              alt='pvc-coin'
            />
            <span className={styles.pvcCoinValue}>
              <AnimatedCounter value={user.wallet.pvc} />
            </span>

            <div className={styles.income}>
              <span className={styles.incomeValue}>{decorateBalance(user.progress.pph)}</span>
              <img
                className={styles.incomeIcon}
                src={PvcIcon}
                alt=''
              />
              / {lang.ui.hours}
            </div>
          </div>
        </div>

        <button
          className={styles.settingsButton}
          onClick={handleOpenSettings}
        >
          <img
            className={styles.settingsButtonIcon}
            src={SettingsIcon}
            alt='settings'
          />
        </button>
      </header>

      <div className={styles.banners}>
        <Banner
          className={styles.bannersContainer}
          imageClassName={styles.bannersImageContainer}
          disabled={true}
          showArrow={!Boolean(timeLeft)}
          image={WordsImage}
          title={lang.home.bannerTextWords}
          description={timeLeft || lang.home.bannerTextReferralsValue}
          link='/words'
        />
        <Banner
          className={styles.bannersContainer}
          imageClassName={styles.bannersImageContainer}
          disabled={true}
          image={CasesImage}
          title={lang.home.bannerTextCases}
          description={`${lang.home.avaliableCases} ${availableCases}`}
          link='/cases'
        />
      </div>
    </div>
  );
};

export default Header;
