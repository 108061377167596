import React from 'react';

import Loading from '../components/Loading';
import { useTelegram } from '../contexts/TelegramContext';
import { UserProvider } from '../contexts/UserContext';
import { HttpProvider } from '../contexts/HttpContext';
import App from '../App';
import { ModalProvider } from '../contexts/ModalContext';
import PcUser from '../components/PcUser/PcUser';
import { useLanguage } from '../contexts/LanguageContext';
import { NotificationProvider } from '../contexts/NotificationsContext';

const AppWrapper = () => {
  const { isReady, isPc } = useTelegram();
  const { lang } = useLanguage();

  if (isPc) return <PcUser />;
  if (!isReady) return <Loading loadingFor={lang.other.telegramData} />;

  return (
    <HttpProvider>
      <UserProvider>
        <ModalProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </ModalProvider>
      </UserProvider>
    </HttpProvider>
  );
};

export default AppWrapper;
