import React from 'react';
import './index.css';
import { useLanguage } from '../../contexts/LanguageContext';
// import TechnicalWorks from "../TechincalWorks";
const Loading = () => {
  const { currentLang } = useLanguage();

  // return <TechnicalWorks />;

  return <div className={`loading-container ${currentLang}`} />;
};

export default Loading;
