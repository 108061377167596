import { useEffect } from 'react';

const useMainElementStyles = () => {
  useEffect(() => {
    document.getElementsByTagName('main')[0].style.overflow = 'hidden';
    document.getElementsByTagName('main')[0].style.background = '#131313';
    document.getElementsByTagName('main')[0].style.paddingBottom = '0';

    return () => {
      document.getElementsByTagName('main')[0].style.overflow = 'initial';
      document.getElementsByTagName('main')[0].style.background = '#0a0a0a';
      document.getElementsByTagName('main')[0].style.paddingBottom = '80px';
    };
  }, []);

  return null;
};

export default useMainElementStyles;
