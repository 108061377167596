import styles from '../RulesModal.module.css';

const StyledWord = ({ word, wordDescription, correctWord }) => {
  const splittedWord = word.split('');

  const getLetterClass = (letter, index) => {
    let remainingCorrectWord = correctWord;

    splittedWord.forEach((l, i) => {
      if (l === remainingCorrectWord[i]) {
        remainingCorrectWord = remainingCorrectWord.replace(l, '_');
      }
    });

    if (correctWord[index] === letter) {
      return styles.correct;
    } else if (remainingCorrectWord.includes(letter)) {
      remainingCorrectWord = remainingCorrectWord.replace(letter, '_');
      return styles.present;
    } else {
      return styles.absent;
    }
  };

  return (
    <div className={styles.wordContainer}>
      <div className={styles.word}>
        {splittedWord.map((letter, index) => (
          <div
            key={index}
            className={`${styles.letter} ${getLetterClass(letter, index)}`}
          >
            {letter}
          </div>
        ))}
      </div>
      <p className={styles.wordDescriptionText}>{wordDescription}</p>
    </div>
  );
};

export default StyledWord;
