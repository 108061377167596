import React from 'react';
import cs from 'classnames';
import { NavLink } from 'react-router-dom';

import { useTelegram } from '../../../contexts/TelegramContext';

import styles from './Footer.module.css';
import './Footer.css';

const FooterLink = ({ url, img, text, disabled }) => {
  const { handleClick } = useTelegram();

  const onLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    handleClick('soft');
  };

  const linkClass = cs(styles.link, {
    [styles.disabled]: disabled,
  });

  return (
    <NavLink
      aria-disabled={disabled}
      to={url}
      className={linkClass}
      onClick={onLinkClick}
    >
      <img
        src={img}
        alt='text-icon'
      />
      <span className={styles.text}>{text}</span>
    </NavLink>
  );
};

export default FooterLink;
