import React from 'react';
import PVCImage from '../../../../../media/png/pvc.png';
import CaseImage from '../../../../../media/png/case-image.png';

import styles from '../RulesModal.module.css';

const Prizes = () => {
  return (
    <div className={styles.prizesWrapper}>
      <h3>Призы</h3>
      <div className={styles.prizes}>
        <div className={styles.prize}>
          <p>x 1 000 000</p>
          <img
            src={PVCImage}
            alt='prize'
          />
        </div>
        <div className={styles.prize}>
          <p>x 1</p>
          <img
            src={CaseImage}
            alt='prize'
          />
        </div>
      </div>
    </div>
  );
};

export default Prizes;
