import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './index.module.css';
import coins from './../../../../media/png/grouped-coins.png';

const Friend = ({ pfp, username, position, percentToEarn, referralClicks = 0 }) => {
  const { lang } = useLanguage();
  return (
    <div className={styles.container}>
      <div className={styles.referral}>
        <img
          className={styles.pfp}
          src={pfp}
          alt=''
        />
        <div className={styles.data}>
          <span className={styles.username}>{username}</span>
          <span className={styles.position}>{position}</span>
        </div>
      </div>

      <div className={styles.clicks}>
        <span className={styles.title}>{lang.friends.title.clicks}</span>
        <span className={styles.count}>{referralClicks}</span>
      </div>
      <div className={styles.earned}>
        <div className={styles.coin}>
          <span>+{percentToEarn}%</span>
          <img
            src={coins}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default Friend;
