import { useState, useCallback, useEffect } from 'react';
import { useHttp } from '../../../contexts/HttpContext';
import { useUser } from '../../../contexts/UserContext';
import { useLanguage } from '../../../contexts/LanguageContext';

const ROWS = 6;
const COLS = 5;

const useWordGame = () => {
  const { post, get } = useHttp();
  const { lang } = useLanguage();
  const { addPvcCoins } = useUser();

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [gameState, setGameState] = useState({
    guessedWords: [{ word: '', matches: [], submitted: false }],
    nextWordUpdate: '',
    rowNumber: 0,
    isGameOver: false,
    isWin: false,
    error: '',
  });

  const fetchWordle = useCallback(async () => {
    setLoading(true);
    const wordle = await get('/api/wordle');

    if (wordle.status === 'error') {
      setLoading(false);
      setGameState((prev) => ({ ...prev, error: wordle.message }));
      return;
    }

    const { history, nextWordUpdate } = wordle.data;
    setHistory(history);
    if (history.length > 0) {
      const newHistory = history.map(({ value, matches }) => ({
        word: value,
        submitted: true,
        matches,
      }));

      const lastWord = history[history.length - 1];

      const isLastWordCorrect =
        lastWord.matches.length === COLS && lastWord.matches.every((m) => m.positionMatch);

      const isGameOver = history.length >= ROWS || isLastWordCorrect;
      const isWin = isGameOver && isLastWordCorrect;

      setGameState((prev) => ({
        ...prev,
        guessedWords: isGameOver ? newHistory : [...newHistory, { word: '', submitted: false, matches: [] }],
        rowNumber: history.length,
        nextWordUpdate: nextWordUpdate,
        isGameOnCooldown: isGameOver || isWin,
      }));
    } else {
      setGameState((prev) => ({ ...prev, nextWordUpdate: nextWordUpdate }));
    }

    setLoading(false);
  }, [get]);

  useEffect(() => {
    fetchWordle();
  }, [fetchWordle]);

  const clearErrorState = useCallback(() => {
    setGameState((prev) => ({ ...prev, error: '' }));
  }, []);

  const onAddLetter = useCallback((letter) => {
    setGameState((prev) => {
      if (prev.isGameOver) return prev;
      if (prev.isWin) return prev;
      if (prev.isGameOnCooldown) return prev;

      const currentWord = (prev.guessedWords[prev.rowNumber]?.word || '') + letter;
      if (currentWord.length > COLS) return prev;

      const newGuessedWords = [...prev.guessedWords];
      newGuessedWords[prev.rowNumber] = { word: currentWord, matches: [], submitted: false };

      return {
        ...prev,
        guessedWords: newGuessedWords,
      };
    });
  }, []);

  const onDeleteLetter = useCallback(() => {
    setGameState((prev) => {
      if (prev.isGameOver) return prev;
      if (prev.isWin) return prev;
      if (prev.isGameOnCooldown) return prev;

      const currentWord = prev.guessedWords[prev.rowNumber]?.word.slice(0, -1) || '';
      const newGuessedWords = [...prev.guessedWords];
      newGuessedWords[prev.rowNumber] = { word: currentWord, matches: [], submitted: false };

      return {
        ...prev,
        guessedWords: newGuessedWords,
      };
    });
  }, []);

  const onSubmitWord = useCallback(async () => {
    if (loading) return;

    setGameState((prev) => {
      if (prev.isGameOver) return prev;
      if (prev.isGameOnCooldown) return prev;
      if (prev.isWin) return prev;
      if (prev.guessedWords.length > ROWS) return prev;

      const submittedWord = prev.guessedWords[prev.rowNumber].word;

      if (submittedWord.length < COLS) {
        return { ...prev, error: lang.wordle.tooShort };
      }

      const checkWord = async () => {
        setLoading(true);
        const word = await post('/api/wordle/check-word', { word: submittedWord });

        if (word.status === 'error') {
          setGameState((p) => ({ ...p, error: word.message }));
          setLoading(false);
          return;
        }

        if (word.data.isCorrect) {
          addPvcCoins(1000000);
        }

        setLoading(false);

        setGameState((p) => {
          const newGuessedWords = [...p.guessedWords];
          newGuessedWords[p.rowNumber] = {
            word: submittedWord,
            matches: word.data?.matches || [],
            submitted: true,
          };

          const newRow = p.rowNumber + 1;
          const isGameOver = newRow >= ROWS || word.data.isCorrect;

          return {
            ...p,
            guessedWords: isGameOver
              ? newGuessedWords
              : [...newGuessedWords, { word: '', matches: [], submitted: false }],
            rowNumber: newRow,
            isWin: word.data.isCorrect,
          };
        });
      };

      checkWord();

      return prev;
    });
  }, [lang.wordle.tooShort, post, loading, setLoading, setGameState]);

  const getLetterStatus = useCallback(
    (letter) => {
      let status = 'default';
      let letterFound = false;

      for (const guess of gameState.guessedWords) {
        if (!guess.submitted || !guess.matches) continue;

        const matchInfo = guess.matches.find((match) => match.letter === letter.toLowerCase());

        if (matchInfo) {
          if (matchInfo.positionMatch) return 'correct';
          status = 'present';
          letterFound = true;
        } else if (guess.word.toLowerCase().includes(letter.toLowerCase())) {
          status = 'absent';
          letterFound = true;
        }
      }

      return letterFound ? status : 'default';
    },
    [gameState.guessedWords],
  );

  return {
    ...gameState,
    loading,
    history,
    clearErrorState,
    onAddLetter,
    onDeleteLetter,
    onSubmitWord,
    getLetterStatus,
  };
};

export default useWordGame;
