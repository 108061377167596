import React from 'react';
import styles from './PrizesList.module.css';
import PrizeListItem from './PrizeListItem';
import { RARITY_TYPES, PRIZES_TYPES } from '../../const';
import { useLanguage } from '../../../../contexts/LanguageContext';

function sortByRarity(prizes) {
  const rarityOrder = [RARITY_TYPES.COMMON, RARITY_TYPES.RARE, RARITY_TYPES.EPIC, RARITY_TYPES.LEGENDARY];
  const specialTypes = [PRIZES_TYPES.ACTIVE, PRIZES_TYPES.PASSIVE, PRIZES_TYPES.TG_PREMIUM];

  const sortPrizes = (array, rarityOrder, specialTypes, field) => {
    const sortByFieldAndType = (arr) =>
      arr.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type.localeCompare(b.type);
        }
        const aValue = specialTypes.includes(a.type) ? Infinity : Number(a[field]);
        const bValue = specialTypes.includes(b.type) ? Infinity : Number(b[field]);
        return aValue - bValue;
      });

    const sortedByRarity = rarityOrder.flatMap((rarity) =>
      sortByFieldAndType(array.filter((obj) => obj.rarity === rarity)),
    );

    const rest = sortByFieldAndType(array.filter((obj) => !rarityOrder.includes(obj.rarity)));

    return [...sortedByRarity, ...rest];
  };

  return sortPrizes(prizes, rarityOrder, specialTypes, 'value');
}

const PrizesList = ({ prizes }) => {
  const { lang } = useLanguage();
  const sortedPrizesList = sortByRarity(prizes);

  return (
    <div className={styles.prizesWrapper}>
      <div className={styles.prizesWrapper}>
        <div className={styles.prizesListWrapper}>
          <h3 className={styles.prizesTitle}>{lang.cases.list}</h3>
          <div className={styles.prizesList}>
            {sortedPrizesList.map((prizeData) => (
              <PrizeListItem
                key={prizeData.id}
                {...prizeData}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrizesList;
