import React from 'react';
import styles from './index.module.css';
import Stat from './Stat/Stat';
import { useUser } from '../../../contexts/UserContext';
import friendsColor from './../../../media/svg/svg.svg';
import friends from './../../../media/png/svg.png';
import question from './../../../media/png/modal-question.png';
import { useModal } from '../../../contexts/ModalContext';
import ModalLayout from '../../modals/ModalLayout/ModalLayout';
import { FriendsStats as ModalFriendsStats } from '../../modals/info/FriendsStats/FriendsStats';
import { useLanguage } from '../../../contexts/LanguageContext';

const FriendsStats = () => {
  const { ref } = useUser();
  const { open } = useModal();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.headline}>
        <span>{lang.friends.stats}</span>
        <button
          className={styles.button}
          onClick={() => open(<ModalLayout element={<ModalFriendsStats />} />)}
        >
          <img
            src={question}
            alt=''
          />
        </button>
      </div>
      <div className={styles.inner}>
        <Stat
          iconUrl={friendsColor}
          statKey={lang.friends.came}
          statValue={ref.referrals.length}
        />
        <Stat
          iconUrl={friends}
          statKey={lang.friends.active}
          statValue={ref.statistics.activeReferrals}
        />
      </div>
    </div>
  );
};

export default FriendsStats;
