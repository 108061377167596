import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { decorateBalance } from '../../../../utils/helpers';

const AnimatedCounter = ({ value }) => {
  const controls = useAnimation();
  const [displayValue, setDisplayValue] = useState(value); // отображаемое значение

  useEffect(() => {
    // Анимируем с текущего значения до нового
    controls.start({
      displayValue: value,
      transition: { duration: 0.3, ease: 'easeOut' }, // плавное начисление за 1 секунду
    });
  }, [value, controls]);

  return (
    <motion.div
      animate={controls}
      initial={{ displayValue: value }}
      onUpdate={(latest) => setDisplayValue(latest.displayValue)}
    >
      {decorateBalance(Math.round(displayValue))}
    </motion.div>
  );
};

export default AnimatedCounter;
