import { useLanguage } from '../../../../contexts/LanguageContext';

import KeyIcon from '../../../../media/svg/key-white.svg';
import BoneIcon from '../../../../media/png/bone-icon.png';
import PlayImage from '../../../../media/png/play-image.png';

import styles from './CaseDetailsModal.module.css';
import { CASES_TYPES } from '../../const';

const ButtonContent = ({ type, cost, loading }) => {
  const { lang } = useLanguage();

  if (loading) {
    return <span className={styles.loading}>{lang.cases.loading}</span>;
  }

  switch (type) {
    case CASES_TYPES.REFERRAL:
      return (
        <>
          <span className={styles.price}>{cost}</span>
          <img
            className={styles.keyIcon}
            src={KeyIcon}
            alt='token'
          />
        </>
      );
    case CASES_TYPES.WORDLE:
      return (
        <>
          <span className={styles.price}>{cost}</span>
          <img
            className={styles.keyIcon}
            src={BoneIcon}
            alt='token'
          />
        </>
      );

    case CASES_TYPES.PARTNER:
      return (
        <img
          className={styles.playImage}
          src={PlayImage}
          alt='play-image'
        />
      );

    case CASES_TYPES.DOOMER:
    case CASES_TYPES.PVC:
    case CASES_TYPES.ALLIN:
      return (
        <>
          <span className={styles.price}>{lang.cases.openCase}</span>
        </>
      );

    default:
      return (
        <>
          <span className={styles.price}>{cost}</span>
        </>
      );
  }
};

export default ButtonContent;
