import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AchievementsLink.module.css';

const AchievementsLink = ({ title }) => {
  return (
    <Link
      to='/achievements'
      className={styles.root}
    >
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <div className={styles.imageOverlay} />
          <div className={styles.image} />
        </div>
      </div>
      <p className={styles.title}>{title}</p>
    </Link>
  );
};

export default AchievementsLink;
