import React from 'react';
import cn from 'classnames';
import { Loading } from '../../../shared/ui';
import styles from './Button.module.css';

const Button = ({ text = 'Play', onClick, className = '', loading = false }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const buttonClasses = cn(styles.button, className);

  return (
    <div className={styles.buttonContainer}>
      <button
        className={buttonClasses}
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? <Loading className={styles.loading} /> : text}
      </button>
    </div>
  );
};

export default Button;
