import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { ShareButton } from '../../../shared/components';

import styles from './Header.module.css';

const Header = () => {
  const { lang } = useLanguage();

  const title = lang.friends.title;
  const CLICKS_FOR_REWARD = '50.000';
  const REQUIRED_FRIENDS_CLICKS = '100';

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{lang.friends.invite}</h1>
      <p className={styles.description}>
        <span>{title.get}</span> <span className={styles.blueText}>{CLICKS_FOR_REWARD} </span>
        <span>{title.clicksForReward}</span>
        <span className={styles.blueText}> {`${REQUIRED_FRIENDS_CLICKS} \n ${title.clicks}`}</span>
        <span>
          {', '}
          {title.percentOfReward}
        </span>
      </p>

      <ShareButton />
    </div>
  );
};

export default Header;
