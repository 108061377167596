import ReactDOM from 'react-dom/client';
import { TelegramProvider } from './contexts/TelegramContext';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from './utils/AppWrapper';
import './media/fonts/stylesheet.css';
import './index.css';
import { LanguageProvider } from './contexts/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <TelegramProvider>
      <LanguageProvider>
        <AppWrapper />
      </LanguageProvider>
    </TelegramProvider>
  </BrowserRouter>,
);
