import cn from 'classnames';
import { useUser } from '../../../contexts/UserContext';
import { useLanguage } from '../../../contexts/LanguageContext';

import ExternalLinkIcon from '../../../media/svg/external_link.svg';
import styles from './ShareButton.module.css';

const ShareButton = ({ url, text, buttonText, showIcon = true, className = '' }) => {
  const { ref } = useUser();
  const { lang } = useLanguage();

  const SHARE_TEXT = text || lang.friends.referralLinkText;
  const SHARE_URL = url || ref.referralLink;
  const BUTTON_TEXT = buttonText || lang.friends.shareButtonText;

  const REFERRAL_LINK = `https://t.me/share/url?url=${encodeURIComponent(SHARE_URL)}&text=${encodeURIComponent(SHARE_TEXT)}&parse_mode=html`;

  const rootClassName = cn(styles.root, className);

  return (
    <a
      href={REFERRAL_LINK}
      className={rootClassName}
      data-text={text}
      data-url={url}
    >
      {BUTTON_TEXT}

      {showIcon && (
        <img
          src={ExternalLinkIcon}
          alt='external link'
          className={styles.icon}
        />
      )}
    </a>
  );
};

export default ShareButton;
