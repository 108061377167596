import TonIcon from '../../../media/png/wallet/ton-icon.png';
import NotCoinIcon from '../../../media/png/wallet/notcoin-icon.png';
import MyIcon from '../../../media/png/wallet/my-icon.png';
import GramCointIcon from '../../../media/png/wallet/gram-icon.png';
import UsdtIcon from '../../../media/png/wallet/usdt-icon.png';
import DoomCoinIcon from '../../../media/png/doomer.png';
import PVCIcon from '../../../media/png/pvc.png';
import DogsCoinIcon from '../../../media/png/dogs-icon.png';

export const COINS_ICONS = {
  TON: TonIcon,
  NOT: NotCoinIcon,
  MY: MyIcon,
  GRAM: GramCointIcon,
  USDT: UsdtIcon,
  DOOMER: DoomCoinIcon,
  PVC: PVCIcon,
  DOGS: DogsCoinIcon,
};

export const COINS_NAMES = {
  USDT: 'USDT',
  NOT: 'NOT',
  TON: 'TON',
  MY: 'MY',
  GRAM: 'GRAM',
  DOGS: 'DOGS',
};

export const INNER_COINS_NAMES = {
  PVC: 'PVC',
  DOOMER: 'DOOMER',
};

export const coinsList = Object.keys(COINS_NAMES).map((key) => ({
  icon: COINS_ICONS[key],
  name: COINS_NAMES[key],
}));

export const innerCoinsList = Object.keys(INNER_COINS_NAMES).map((key) => ({
  icon: COINS_ICONS[key],
  name: INNER_COINS_NAMES[key],
}));
