import React, { createContext, useContext, useState, useEffect } from 'react';
import { getLanguage, setLanguage, translations } from '../utils/translations';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(getLanguage());

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const value = {
    lang: translations[lang],
    currentLang: lang,
    setLanguage: setLang,
  };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
