import React from 'react';

import { useLanguage } from '../../../../contexts/LanguageContext';
import TonIcon from './../../../../media/png/ton-icon.png';
import Button from '../../ui/Button';
import styles from './Footer.module.css';

const WinnerContent = ({ gift, onWithdraw, loadingWithdraw }) => {
  const { lang } = useLanguage();
  const TON_WINNER_PRIZE = gift.price.toFixed(2);
  const TON_TEXT = gift.vault;

  return (
    <div className={styles.footer}>
      <div className={styles.winnerContent}>
        <div className={styles.winnerContentText}>
          <h1 className={styles.winnerContentTitle}>{lang.box.congratulations}</h1>
          <p className={styles.winnerContentDescription}>{lang.box.toContinue}</p>
        </div>
        <div className={styles.winnerPrize}>
          <img
            className={styles.winnerPrizeIcon}
            src={TonIcon}
            alt='ton'
          />
          <p className={styles.winnerPrizeText}>
            {TON_WINNER_PRIZE} {TON_TEXT}
          </p>
        </div>
      </div>
      <Button
        className={styles.winnerButton}
        text={lang.box.withdraw}
        onClick={onWithdraw}
        loading={loadingWithdraw}
      />
    </div>
  );
};

export default WinnerContent;
