import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTelegram } from './TelegramContext';
import { useUser } from './UserContext';
import { useHttp } from './HttpContext';
import { decorateError } from '../utils/helpers';
import CryptoJS from 'crypto-js';

const ClickerContext = createContext();

export const ClickerProvider = ({ children }) => {
  const [clicks, setClicks] = useState([]);
  const clickCountRef = useRef(0);
  const doubleTapCountRef = useRef(0);
  const [timer, setTimer] = useState(null);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const { user, consumeEnergy, addPvc, addExperience, setUser, actives } = useUser();
  const { handleClick, animations } = useTelegram();
  const { post } = useHttp();
  const userRef = useRef(user);

  const buttonRef = useRef(null);
  const imgContainerRef = useRef(null);

  const clickerResetInterval = parseInt(process.env.REACT_APP_CLICKER_STOP_TIME, 10);
  const clickerSendInterval = parseInt(process.env.REACT_APP_CLICKER_INTERVAL_TIME, 10);

  useEffect(() => {
    const button = buttonRef.current;
    const handleTouchStart = (e) => {
      e.preventDefault();
      if (button.disabled) {
        e.preventDefault();
        return;
      }
      if (user.progress.energy <= 0) {
        e.preventDefault();
        return;
      }
      clickerClick(e, button);
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
    };

    const handleTouchEnd = (e) => {
      e.preventDefault();
    };

    if (button) {
      button.addEventListener('touchstart', handleTouchStart, { passive: false });
      button.addEventListener('touchmove', handleTouchMove, { passive: false });
      button.addEventListener('touchend', handleTouchEnd, { passive: false });

      return () => {
        button.removeEventListener('touchstart', handleTouchStart);
        button.removeEventListener('touchmove', handleTouchMove);
        button.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [handleClick, user.progress.energy]);

  useEffect(() => {
    return () => {
      // Send data to server on unmount
      sendDataToServer();
    };
  }, []);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  useEffect(() => {
    userRef.current = user;
    if (user.progress.expirience >= user.level.requiredExpirience) {
      sendDataToServer();
    }
  }, [user]);

  const isDoubleClick = () => {
    if (actives && actives.length > 0) {
      for (const active of actives) {
        if (active.active.bonus.type === 'DOUBLE_TAP_CHANCE' && active.isActive) {
          return true;
        }
      }
    }
    if (user.userManagers && user.userManagers.length > 0) {
      const random = Math.random() * 100;
      return random < user.userManagers[0].manager.doubletapChance;
    }
    return false;
  };

  const addVisualClick = (e, button, isDouble) => {
    if (!animations) return;
    const rect = button.getBoundingClientRect();

    const newClicks = Array.from(e.touches).map((touch) => {
      const x = touch.clientX - rect.left + 25;
      const y = touch.clientY - rect.top + 100;

      const angle = Math.random() * 90 - 45;
      return { id: Date.now() + Math.random(), x, y, angle, isDouble };
    });
    button.classList.add('clicked');
    setClicks((currentClicks) => [...currentClicks, ...newClicks]);
    setTimeout(() => {
      setClicks((currentClicks) =>
        currentClicks.filter((click) => !newClicks.some((newClick) => newClick.id === click.id)),
      );
    }, 1000);
    setTimeout(() => {
      button.classList.remove('clicked');
      imgContainerRef.current.style.transform = `skew(0deg, 0deg)`;
    }, 100);
  };

  const sendDataToServer = () => {
    if (clickCountRef.current === 0 && doubleTapCountRef.current === 0) return;
    const currentUser = userRef.current;

    if (!isAwaiting) {
      setIsAwaiting(true);
      const clickCount = clickCountRef.current;
      const doubleTapCount = doubleTapCountRef.current;
      clickCountRef.current = 0;
      doubleTapCountRef.current = 0;

      const clickTimestamp = Date.now();
      const clickKey = CryptoJS.SHA256(
        clickCount + process.env.REACT_APP_CLICKER_PHRASE + clickTimestamp,
      ).toString();

      post(`/api/click/tap`, {
        clickCount: clickCount,
        doubleTapCount: doubleTapCount,
        energy: currentUser.progress.energy,
        timestamp: clickTimestamp,
        key: clickKey,
      }).then((r) => {
        if (r.status === 'error') {
          alert(decorateError(r.message));
        } else {
          setUser((prevUser) => {
            const updatedUser = {
              ...r.data,
              nextWordUpdate: prevUser.nextWordUpdate,
              availableCases: prevUser.availableCases,
            };

            updatedUser.wallet.pvc +=
              clickCountRef.current * updatedUser.progress.ppt +
              doubleTapCountRef.current * updatedUser.progress.ppt;
            updatedUser.progress.expirience += clickCountRef.current;

            updatedUser.progress.energy -= clickCountRef.current;
            updatedUser.userManagers = prevUser.userManagers;
            return updatedUser;
          });
        }
        setIsAwaiting(false);
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      sendDataToServer();
    }, clickerSendInterval);

    return () => clearInterval(intervalId);
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(sendDataToServer, clickerResetInterval));
  };

  const clickerClick = (e, button) => {
    e.preventDefault();
    const isDouble = isDoubleClick();
    resetTimer();
    if (isDouble) {
      doubleTapCountRef.current += 1;
    }
    addPvc(isDouble ? user.progress.ppt * 2 : user.progress.ppt);
    addExperience(1);
    clickCountRef.current += 1;
    consumeEnergy(1);
    addVisualClick(e, button, isDouble);
    handleClick();
  };

  return (
    <ClickerContext.Provider value={{ clicks, buttonRef, imgContainerRef }}>
      {children}
    </ClickerContext.Provider>
  );
};

export const useClicker = () => useContext(ClickerContext);
