import React from 'react';
import styles from './GiftsModal.module.css';
import TonIcon from '../../../../media/png/ton-icon.png';
import { useLanguage } from '../../../../contexts/LanguageContext';

const GiftsHeader = () => {
  const { lang, currentLang } = useLanguage();

  const TON_AMOUNT = 1;

  const ton = {
    ru: {
      price: 500,
      vault: lang.box.vault,
    },
    en: {
      price: 5,
      vault: lang.box.vault,
    },
  };

  const TON_PRICE = ton[currentLang].price;
  const TON_VAULT = ton[currentLang].vault;

  return (
    <div className={styles.header}>
      <h1 className={styles.title}>{lang.box.giftsTitle}</h1>
      <div className={styles.tonWrapper}>
        <span className={styles.tonAmount}>{TON_AMOUNT}</span>
        <img
          src={TonIcon}
          alt='TON'
          className={styles.tonIcon}
        />
        <span className={styles.tonAmount}>
          <p className={styles.dash}>~</p> {TON_PRICE} {TON_VAULT}
        </span>
      </div>
      <p className={styles.description}>{lang.box.giftsDescription}</p>
    </div>
  );
};

export default GiftsHeader;
