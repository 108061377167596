import React from 'react';
import cs from 'classnames';

import styles from './CoinsList.module.css';

const CoinsListItem = ({ icon, name, coin, selectedCoin, onCoinClick }) => {
  const handleClick = () => {
    if (selectedCoin.name === name) return;
    
    onCoinClick(coin);
  };

  const itemWrapperStyles = cs(styles.itemIconWrapper, {
    [styles.selected]: selectedCoin.name === name,
  });

  return (
    <div className={styles.item} onClick={handleClick}>
      <div className={itemWrapperStyles}>
        <div className={styles.itemIcon}>
          <img src={icon} alt="coin-icon" />
        </div>
      </div>
    </div>
  );
};

export default CoinsListItem;