import React from 'react';

import StableBox from './StableBox';
// import AnimatedBox from './AnimatedBox';

const Box = ({ completed, gift }) => {
  // if (!completed) {
  //   return <AnimatedBox />;
  // // }

  return (
    <StableBox
      gift={gift}
      completed={completed}
    />
  );
};

export default Box;
