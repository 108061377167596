import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './GiftsModal.module.css';

const GiftsElement = ({ smallIcon, label, price }) => {
  const VAULT_NAME = 'TON';
  const { lang } = useLanguage();

  return (
    <div className={styles.element}>
      <div className={styles.elementContent}>
        <img
          className={styles.elementIcon}
          src={smallIcon}
          alt={label}
        />
        <span>{label}</span>
      </div>
      <span className={styles.elementPrice}>
        {lang.box.to} {price} {VAULT_NAME}
      </span>
    </div>
  );
};

export default GiftsElement;
