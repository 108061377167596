import React from 'react';
import styles from './index.module.css';
import { useLanguage } from '../../../contexts/LanguageContext';

const Header = () => {
  const { lang } = useLanguage();

  return (
    <div className={styles.header}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} />
        <div className={styles.backgroundGradient} />
      </div>
      <div className={styles.description}>
        <span className={styles.title}>{lang.tasks.title}</span>
        <span className={styles.descriptionText}>{lang.tasks.description}</span>
      </div>
    </div>
  );
};

export default Header;
