import React, { memo } from 'react';

import KeyboardKey from './KeyboardKey.jsx';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { keyboard } from '../../../const';
import styles from './Keyboard.module.css';

const Keyboard = ({ onSubmitWord, onDeleteLetter, onAddLetter, vibrate, getLetterStatus }) => {
  const { currentLang } = useLanguage();
  const layout = keyboard[currentLang];

  return (
    <div className={styles.keyboard}>
      {Object.keys(layout).map((rowKey, rowIndex) => (
        <div
          key={rowIndex}
          className={styles.keyboardRow}
        >
          {layout[rowKey].map((key, keyIndex) => {
            const keyStatus = getLetterStatus(key);
            return (
              <KeyboardKey
                key={keyIndex}
                keyboardKey={key}
                onSubmitWord={onSubmitWord}
                onDeleteLetter={onDeleteLetter}
                onAddLetter={onAddLetter}
                vibrate={vibrate}
                status={keyStatus}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default memo(Keyboard);
