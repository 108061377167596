import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';

import ArrowRightIcon from '../../../media/svg/arrow-right.svg';
import styles from './Banner.module.css';

const Banner = ({
  title,
  description,
  disabled = false,
  image,
  link,
  showArrow = false,
  className = '',
  imageClassName = '',
  textClassName = '',
}) => {
  const bannerClass = cs(className, styles.content, {
    [styles.disabled]: Boolean(disabled),
  });

  const borderClass = cs(styles.border, {
    [styles.disabled]: disabled,
  });

  const imageOverlayClass = cs(styles.imageOverlay, {
    [styles.disabled]: disabled,
  });

  const imageContainerClass = cs(styles.imageContainer, imageClassName);
  const textContainerClass = cs(textClassName, styles.textContainer);

  return (
    <Link
      className={styles.root}
      to={link}
    >
      <div className={borderClass} />
      <div className={bannerClass}>
        <div className={textContainerClass}>
          <div className={styles.text}>{title}</div>
          <div className={styles.link}>
            <span className={styles.description}>{description}</span>
            {showArrow && (
              <img
                src={ArrowRightIcon}
                alt='arrow-right'
              />
            )}
          </div>
        </div>
        <div className={imageContainerClass}>
          <div className={imageOverlayClass} />
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
      </div>
    </Link>
  );
};

export default Banner;
