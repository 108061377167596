import React from 'react';
import styles from './index.module.css';
import pvc from './../../../../media/png/pvc.png';
import doomer from './../../../../media/png/doomer.png';
import bone from './../../../../media/png/bone-icon.png';

import { useModal } from '../../../../contexts/ModalContext';
import ModalLayout from '../../../modals/ModalLayout/ModalLayout';
import arrow from './../../../../media/svg/arrow-right-2.svg';
import approved from './../../../../media/svg/green-arrow.svg';
import Check from '../../../modals/tasks/Check/Check';

const ListElement = ({ type, icon, name, bonusName, bonusType, isCompleted, link, id }) => {
  const { open } = useModal();
  let img;

  switch (bonusType) {
    case 'PVC':
      img = pvc;
      break;
    case 'DOOMCOINS':
      img = doomer;
      break;
    case 'BONE':
      img = bone;
      break;
    default:
      img = '';
  }

  let returnedValue;

  if (isCompleted) {
    returnedValue = (
      <img
        className={styles.approved}
        src={approved}
        alt=''
      />
    );
  } else {
    returnedValue = (
      <button
        onClick={() =>
          open(
            <ModalLayout
              element={
                <Check
                  id={id}
                  icon={icon}
                  text={name}
                  url={link}
                  type={type}
                />
              }
            />,
          )
        }
        className={styles.checkButton}
      >
        <img
          src={arrow}
          alt=''
        />
      </button>
    );
  }

  return (
    <div className={styles.container}>
      <img
        src={icon}
        className={styles.icon}
        alt=''
      />
      <div className={styles.text}>
        <span className={styles.name}>{name}</span>
        <div className={styles.reward}>
          <span>+{bonusName}</span>
          {img && (
            <img
              src={img}
              alt=''
            />
          )}
        </div>
      </div>
      {returnedValue}
    </div>
  );
};

export default ListElement;
