import React from 'react';
import Prizes from '../../RulesModal/ui/Prizes';
import styles from '../Game.module.css';

const OverlayMessage = ({ showOverlay, overlayText, showPrizes = false }) => {
  if (!showOverlay)
    return (
      <div
        className={styles.overlay}
        style={{ opacity: 0 }}
      >
        <span className={styles.overlayText}>{overlayText}</span>
        {showPrizes && <Prizes />}
      </div>
    );

  return (
    <div className={styles.overlay}>
      <span className={styles.overlayText}>{overlayText}</span>
      {showPrizes && <Prizes />}
    </div>
  );
};

export default OverlayMessage;
