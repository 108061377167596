import { useEffect, useState, useCallback } from 'react';

const formatTimeDifference = (remainingTime) => {
  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;

  const pad = (num) => String(num).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

const useTimer = ({ endTime, text }) => {
  const calculateTimeLeft = useCallback(() => {
    const endTimeMs = new Date(endTime).getTime();
    const difference = Math.max(0, Math.floor((endTimeMs - Date.now()) / 1000));
    return formatTimeDifference(difference);
  }, [endTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const updateTime = () => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (newTimeLeft === '00:00:00') {
        clearInterval(timer);
      }
    };

    const endTimeMs = new Date(endTime).getTime();
    if (endTimeMs <= Date.now()) {
      setTimeLeft('00:00:00');
      return;
    }

    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, [endTime, calculateTimeLeft]);

  if (!endTime || timeLeft.includes('00:00:00') || timeLeft.includes('NaN')) return null;

  const timeText = text ? `${text}: ${timeLeft}` : timeLeft;

  return timeText;
};

export default useTimer;
