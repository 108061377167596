import { useLanguage } from '../../../../contexts/LanguageContext';
import { useUser } from '../../../../contexts/UserContext';
import { positionImages } from '../../../../utils/helpers';

import FriendsIcon from '../media/friends.svg';
import HomeIcon from '../media/main.png';
import EarnIcon from '../media/earn.svg';
import ImprovementsIcon from '../media/upgrades.svg';
import WalletIcon from '../media/wallet.svg';

const useNavLinks = () => {
  const { lang } = useLanguage();
  const { user } = useUser();
  const position = user.position.name || 'Worker';
  const CoinImage = positionImages[position].coin || HomeIcon;
  return [
    {
      url: '/friends',
      img: FriendsIcon,
      text: lang.ui.footerLinks.friends,
    },
    {
      url: '/income',
      img: EarnIcon,
      text: lang.ui.footerLinks.earn,
    },
    {
      url: '/',
      img: CoinImage,
      text: lang.ui.footerLinks.main,
    },
    {
      url: '/improvements',
      img: ImprovementsIcon,
      text: lang.ui.footerLinks.upgrades,
    },
    {
      url: '/wallet',
      img: WalletIcon,
      text: lang.ui.footerLinks.wallet,
    },
  ];
};

export default useNavLinks;
