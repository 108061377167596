import React from 'react';
import Cell from './Cell';
import styles from '../Game.module.css';

const Grid = ({ rows = 6, cols = 5, guessedWords = [] }) => {
  return (
    <div className={styles.grid}>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <>
          {Array.from({ length: cols }).map((_, colIndex) => (
            <Cell
              key={colIndex}
              word={guessedWords[rowIndex]?.word}
              submitted={guessedWords[rowIndex]?.submitted}
              matches={guessedWords[rowIndex]?.matches}
              colIndex={colIndex}
              letter={guessedWords[rowIndex]?.word[colIndex]}
            />
          ))}
        </>
      ))}
    </div>
  );
};

export default Grid;
