import React from 'react';
import cs from 'classnames';

import styles from './Coin.module.css';

const ClickNumber = ({ isDouble, ppt, x, y, angle }) => {
  const clickClass = cs(styles.clickNumber, {
    [styles.double]: isDouble,
  });

  return (
    <div
      className={clickClass}
      style={{
        left: x,
        top: y,
        '--random-angle': `${angle}deg`,
      }}
    >
      <span>+{isDouble ? 2 * ppt : ppt}</span>
    </div>
  );
};

export default ClickNumber;
