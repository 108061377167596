import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useUser } from '../../../../contexts/UserContext';
import { useModal } from '../../../../contexts/ModalContext';

import TasksImage from '../../../../media/png/home/dogs-cases-icon.png';
import GiftsImage from '../../../../media/png/home/gifts-icon.png';
import ArrowRightIcon from '../../../../media/svg/arrow-right.svg';
import QuestionIcon from '../../../../media/png/modal-question.png';

import ModalLayout from '../../../modals/ModalLayout/ModalLayout';
import Level from '../../../modals/info/Level/Level';
import AchievementsLink from '../AchievementsLink/AchievementsLink';
import ActivesLink from '../ActivesLink/ActivesLink';

import { Banner } from '../../../../shared/ui';

import styles from './Footer.module.css';

const Footer = () => {
  const { user } = useUser();
  const { lang } = useLanguage();
  const { open } = useModal();

  const onWorkerModalOpen = () => {
    open(<ModalLayout element={<Level />} />);
  };

  return (
    <div className={styles.root}>
      <div className={styles.blueOverlay} />

      <div className={styles.banners}>
        <Banner
          className={styles.bannersContainer}
          textClassName={styles.bannersText}
          image={TasksImage}
          title={lang.home.bannerTextTasks}
          description={lang.home.toTasks}
          showArrow={true}
          link='/income'
        />
        <Banner
          className={styles.bannersContainer}
          textClassName={styles.bannersText}
          image={GiftsImage}
          title={lang.home.bannerTextGifts}
          description={lang.home.openGift}
          showArrow={true}
          link='/box'
        />
      </div>
      <div className={styles.content}>
        <div className={styles.stats}>
          <div className={styles.container}>
            <div className={styles.experienceProgress}>
              <Link
                to={'/positions'}
                className={styles.link}
              >
                <span>{user.position.name}</span>
                <img
                  src={ArrowRightIcon}
                  alt=''
                />
              </Link>
              <span className={styles.level}>
                lvl <span>{user.progress.level}</span>{' '}
                <button
                  onClick={onWorkerModalOpen}
                  className={styles.infoButton}
                >
                  <img
                    src={QuestionIcon}
                    alt=''
                  />
                </button>
              </span>
            </div>
            <div className={styles.worker}>
              <div
                className={styles.workerBar}
                style={{ width: `${(user.progress.expirience / user.level.requiredExpirience) * 100}%` }}
              />
            </div>
          </div>
          <div className={styles.energyProgress}>
            <span>
              {user.progress.energy <= 0 ? 0 : user.progress.energy}/
              {user.progress.maxEnergy === -1 ? '∞' : user.progress.maxEnergy}
            </span>
            <div className={styles.energy}>
              <div
                className={styles.energyBar}
                style={{
                  width: `${user.progress.energy <= 0 ? 0 : (user.progress.energy / user.progress.maxEnergy) * 100}%`,
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.navButtons}>
          <AchievementsLink title={lang.home.achievements} />
          <ActivesLink title={lang.home.actives} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
