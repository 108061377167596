import { memo } from 'react';
import cs from 'classnames';

import approveIcon from '../../../../../media/png/approve-icon.png';
import styles from './Keyboard.module.css';

const KeyboardKey = ({ keyboardKey, onSubmitWord, onDeleteLetter, onAddLetter, vibrate, status }) => {
  const buttonStyle = cs(styles.keyboardKey, {
    [styles.backspace]: keyboardKey === 'backspace',
    [styles.enter]: keyboardKey === 'enter',
    [styles.correct]: status === 'correct',
    [styles.present]: status === 'present',
    [styles.absent]: status === 'absent',
    [styles.default]: status === 'default',
  });

  const handleClick = () => {
    vibrate('light');

    if (keyboardKey === 'backspace') {
      onDeleteLetter();
    } else if (keyboardKey === 'enter') {
      onSubmitWord();
    } else {
      onAddLetter(keyboardKey);
    }
  };

  return (
    <button
      className={buttonStyle}
      onClick={handleClick}
    >
      {keyboardKey === 'backspace' ? (
        '⌫'
      ) : keyboardKey === 'enter' ? (
        <img
          src={approveIcon}
          alt='enter'
        />
      ) : (
        keyboardKey
      )}
    </button>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.keyboardKey === nextProps.keyboardKey && prevProps.status === nextProps.status;
};

export default memo(KeyboardKey, areEqual);
