import React from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

import CloseIcon from '../../../Wallet/components/CloseButton';
import Button from '../../ui/Button';
import styles from './SuccessModal.module.css';

const SuccessModal = () => {
  const { close } = useModal();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        onClick={close}
      >
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.content}>
        <h1 className={styles.title}>{lang.box.successTitle}</h1>
        <p className={styles.text}>{lang.box.successText}</p>
        <Button
          onClick={close}
          text={lang.box.okay}
        />
      </div>
    </div>
  );
};

export default SuccessModal;
