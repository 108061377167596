import React, { memo } from 'react';
import cs from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import styles from '../Game.module.css';

const Cell = ({ letter = '', matches = [], submitted = false, word = '', colIndex }) => {
  const getLetterStatus = () => {
    if (!submitted) return 'default';

    const match = matches.find((m) => m.letter === letter.toLowerCase() && m.index === colIndex);
    if (!match) return 'absent';
    return match.positionMatch ? 'correct' : 'present';
  };

  const status = getLetterStatus();

  const cellContentStyles = cs(styles.cellContent, {
    [styles.default]: status === 'default',
    [styles.absent]: status === 'absent',
    [styles.present]: status === 'present',
    [styles.correct]: status === 'correct',
  });

  return (
    <div className={styles.cell}>
      <AnimatePresence>
        {letter ? (
          <motion.div
            key='letter'
            className={cellContentStyles}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ type: 'spring', stiffness: 500, damping: 30 }}
          >
            {letter}
          </motion.div>
        ) : (
          <motion.div
            key='empty'
            className={cellContentStyles}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.letter === nextProps.letter &&
    prevProps.submitted === nextProps.submitted &&
    JSON.stringify(prevProps.matches) === JSON.stringify(nextProps.matches)
  );
};

export default memo(Cell, areEqual);
