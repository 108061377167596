import React from 'react';
import cs from 'classnames';
import styles from './Loading.module.css';

const Loading = ({ className = '', show = false }) => {
  const loadingStyles = cs(styles.loading, className);

  if (!show) return null;

  return (
    <div className={loadingStyles}>
      <div className={styles.spinner} />
    </div>
  );
};

export default Loading;
