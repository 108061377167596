import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ClicksInfoModal.module.css';

const ModalListElement = ({ icon, label, link, onModalClose }) => {
  return (
    <div className={styles.element}>
      <div className={styles.elementContent}>
        <img
          className={styles.elementIcon}
          src={icon}
          alt='element-icon'
        />
        <p className={styles.elementLabel}>{label}</p>
      </div>

      <Link
        className={styles.elementLink}
        to={link}
        onClick={onModalClose}
      >
        <span>&#8594;</span>
      </Link>
    </div>
  );
};

export default ModalListElement;
