import React from 'react';
import styles from './TechnicalWorks.module.css';
import technicalWorksImage from '../../media/png/technical-works.png';
import { useLanguage } from '../../contexts/LanguageContext';

const TechnicalWorks = () => {
  const { lang } = useLanguage();
  return (
    <div className={styles.container}>
      <img 
        src={technicalWorksImage} 
        alt="Technical Works" 
        className={styles.image}
      />
      <h1 className={styles.title}>{lang.technicalWorks.title}</h1>
      <p className={styles.description}>
        {lang.technicalWorks.description}
      </p>
    </div>
  );
};

export default TechnicalWorks;
