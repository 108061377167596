import bronze from '../media/png/coins/bronze.png';
import silver from '../media/png/coins/silver.png';
import gold from '../media/png/coins/gold.png';
import platina from '../media/png/coins/platina.png';
import diamond from '../media/png/coins/diamond.png';

export const decorateBalance = (balance) => {
  return new Intl.NumberFormat('en-US').format(balance).replace(/,/gi, ' ');
};
export const decorateError = (e) => {
  return `Произошла ошибка!\n\n${e}\n\nПожалуйста, напишите в поддержку`;
};
export const positionImages = {
  Worker: {
    coin: bronze,
    color: '#70592a',
  },
  'Futures Trader': {
    coin: silver,
    color: '#595959',
  },
  'TON Whale': {
    coin: gold,
    color: '#8f7329',
  },
  'Token Founder': {
    coin: platina,
    color: '#4aa69e',
  },
  'EcoSystem Founder': {
    coin: diamond,
    color: '#9b2f82',
  },
};
