import React from 'react';
import styles from './index.module.css';
import Friend from './Friend/Friend';
import doomer from './../../../media/png/doomer.png';
import { useUser } from '../../../contexts/UserContext';
import { useLanguage } from '../../../contexts/LanguageContext';

const List = () => {
  const { ref } = useUser();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <span>{lang.friends.list}</span>
        <button></button>
      </div>
      <div className={styles.inner}>
        {ref.referrals && ref.referrals.length > 0 ? (
          ref.referrals.map((ref, index) => (
            <Friend
              pfp={doomer}
              doomerEarned={0}
              referralClicks={ref?.referralClicks}
              percentToEarn={ref?.position?.referralPercent}
              username={ref?.username || `Friend ${index}`}
              position={ref?.position?.name || 'Position'}
            />
          ))
        ) : (
          <span className={styles.empty}>{lang.friends.empty}</span>
        )}
        <span className={styles.more}>{lang.friends.invite}</span>
      </div>
    </div>
  );
};

export default List;
