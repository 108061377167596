import React, { useMemo } from 'react';

import { decorateBalance } from '../../utils/helpers';
import Footer from './components/Footer/Footer';
import Box from './components/Box';

import useMainElementStyles from './hooks/useMainElementStyles';
import useBox from './hooks/useBox';
import useGiftsList from './hooks/useGiftsList';

import { Loading } from '../../shared/ui';

import styles from './BoxPage.module.css';

export const BoxPage = () => {
  const { boxState, loadingWithdraw, loadingScore, onClicksInfoModalOpen, onGiftsModalOpen, onWithdraw } =
    useBox();

  useMainElementStyles();

  const { giftsList } = useGiftsList();

  const currentScore = decorateBalance(boxState.currentScore).replace(/,/gi, ' ');
  const maxScore = decorateBalance(boxState.maxScore).replace(/,/gi, ' ');

  const gift = useMemo(() => giftsList[boxState.level - 1], [boxState.level, giftsList]);

  if (loadingScore || !gift) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.background} />
        <Box
          completed={boxState.clicksCompleted}
          gift={gift}
        />
      </div>
      <Footer
        gift={gift}
        loadingWithdraw={loadingWithdraw}
        completed={boxState.clicksCompleted}
        onWithdraw={onWithdraw}
        currentScore={currentScore}
        maxScore={maxScore}
        onClicksInfoModalOpen={onClicksInfoModalOpen}
        onGiftsModalOpen={onGiftsModalOpen}
      />
    </div>
  );
};
