import React from 'react';
import styles from '../RulesModal.module.css';

const Button = ({ text = 'Play', onClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <button
        className={styles.button}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
