import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';

import styles from './Header.module.css';

const Header = () => {
  const { lang } = useLanguage();

  return (
    <div className={styles.root}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} />
        <div className={styles.backgroundGradient} />
      </div>
      <div className={styles.description}>
        <span className={styles.title}>{lang.wallet.title}</span>
        <span className={styles.descriptionText}>{lang.wallet.description}</span>
      </div>
    </div>
  );
};

export default Header;
