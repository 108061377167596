import React from 'react';
import styles from './AnimatedBox.module.css';

const StableBox = ({ gift = {}, completed }) => {
  const BoxContentImage = completed ? gift.largeIconOpen : gift.largeIconClose;

  return (
    <div className={styles.container}>
      {completed && <div className={styles.tons} />}
      <div
        className={styles.box}
        style={{ backgroundImage: `url(${BoxContentImage})` }}
      />
      <div className={styles.boxShadow} />
    </div>
  );
};

export default StableBox;
