// TODO: Refactor ClickerContext, styles and ClickNumber
import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import { useUser } from '../../../../contexts/UserContext';
import { useClicker } from '../../../../contexts/ClickerContext';
import { positionImages } from '../../../../utils/helpers';

import ClickNumber from './ClickNumber';
import bronze from './../../../../media/png/coins/bronze.png';
import styles from './Coin.module.css';

const Coin = () => {
  const { user } = useUser();
  const { clicks, buttonRef, imgContainerRef } = useClicker();

  const position = user.position.name || 'Worker';
  const isDisabled = user.progress.energy <= 0;

  const CoinClass = cn(styles.coin, {
    [styles.disabled]: isDisabled,
  });

  const AnimationConfig = {
    transition: { type: 'spring', stiffness: 400, damping: 10 },
    initial: { opacity: 0, scale: 0.8 },
    animate: isDisabled ? { opacity: 1, scale: 0.95, filter: 'grayscale(1)' } : { opacity: 1, scale: 1 },
    whileTap: isDisabled ? {} : { scale: 0.98 },
  };

  return (
    <div className={styles.root}>
      <motion.div
        {...AnimationConfig}
        ref={buttonRef}
        className={CoinClass}
        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
        style={{ '--bg-color-clicker': `${positionImages[position].color || '#70592a'}` }}
      >
        <div
          ref={imgContainerRef}
          className={styles.imageContainer}
        >
          <img
            src={positionImages[position].coin || bronze}
            alt='clicker'
          />
        </div>
      </motion.div>
      <div className={styles.clicksContainer}>
        {clicks.map(({ id, isDouble, x, y, angle }) => (
          <ClickNumber
            key={id}
            isDouble={isDouble}
            ppt={user.progress.ppt}
            x={x}
            y={y}
            angle={angle}
          />
        ))}
      </div>
    </div>
  );
};

export default Coin;
